.product-text {
  white-space: pre-wrap;
  font-size: 18px;
  margin: 125px 0 90px;

  @media screen and (max-width: 1199px) {
    margin: 60px 0 40px;
  }
}

.product-upper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    column-gap: 25px;
  }
}

.prod-back{
  margin-top: 50px;
  display: block;
}