.subcategories-tiles-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width: 1199px) {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 50px;
  }
}

.subcategories-back {
  margin-top: 30px;
  margin-bottom: 100px;
  display: block;
}

.subcategory-tiles-item {
  min-height: 40px;
  color: #000;
  text-decoration: none;
  border: 1px #1976D2 solid;
  padding: 10px 30px;
  background: #fff;
  transition: all 0.5s ease-out;
  @media screen and (max-width: 1199px) {
    display: inline-flex;
    flex-direction: column;
  }

  @media screen and (min-width: 1199px) {
    &:nth-child(3n+2), &:nth-child(3n+3) {
      margin-left: -1px;
      margin-top: -1px;
    }

    &:nth-child(3n+1){
      margin-top: -1px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: #3b3b3b;
    background: #bbdefb;
  }

  .category-tiles-count {
    font-size: 14px;
    color: #666;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .category-tiles-name {
    font-size: 18px;
    color: #000;
    padding: 5px 0 0 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
    @media screen and (max-width: 1199px) {
      padding: 0;
    }
  }
}
