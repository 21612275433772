.login-form-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.login-form-container input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  -webkit-appearance: none;
  color: #444444;
  border: 1px solid #1976d2;
  background: #fff;
  transition: border 0.3s ease;
  margin-bottom: 15px;
  &::placeholder {
    color: #444444;
  }
  &:focus {
    outline: none;
    border-color: #03a9f4;
  }
}

.login-form-error {
  margin-top: 0;
  margin-bottom: 15px;
  color: #ff0000;
  font-size: 12px;
}