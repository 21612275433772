.carousel-header {
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.carousel-container {
  background: #1976d2;
  width: 100%;
  height: 370px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  padding: 10px;
  margin-bottom: 100px;
  position: relative;

  @media screen and (max-width: 1199px) {
    display: none;
  }

  a {
    text-decoration: none;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active .carousel-title {
      color: #3b3b3b;
    }
  }

  .carousel-arrow {
    width: 60px;
    height: 60px;
    background: rgba(187, 222, 251, 0.4);

    &:hover,
    &:focus,
    &:active .carousel-title {
      background: rgba(187, 222, 251, 0.6);
      cursor: pointer;
    }
  }

  .carousel-arrow-left {
    position: absolute;
    top: 150px;
    left: 20px;
    .carousel-arrow-left-container {
      position: relative;
      width: 60px;
      height: 60px;
      &::after {
        content: "";
        border: solid black;
        border-width: 0 2px 2px 0;
        position: absolute;
        padding: 5px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        left: 25px;
        top: 24px;
      }
    }
  }

  .carousel-arrow-right {
    position: absolute;
    top: 150px;
    right: 20px;
    .carousel-arrow-right-container {
      position: relative;
      width: 60px;
      height: 60px;
      &::after {
        content: "";
        border: solid black;
        border-width: 0 2px 2px 0;
        position: absolute;
        padding: 5px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        right: 25px;
        top: 24px;
      }
    }
  }
}

.carousel-item-container {
  position: relative;
  padding: 20px 30px;
  background: #fff;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  .carousel-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:  flex-start;

    .carousel-image {
      width: 189px;
      height: 189px;
      margin: 0 auto;
    }

    .carousel-title {
      font-size: 18px;
      color: #000;
      text-decoration: none;
      margin: 20px 0 0;
      transition: all 0.5s ease-out;

      &:hover,
      &:focus,
      &:active {
        color: #3b3b3b;
      }
    }

    &:hover,
    &:focus,
    &:active .carousel-price {
      text-decoration: none;
    }

    .carousel-price {
      font-weight: bold;
      font-size: 18px;
      color: #000;
      text-decoration: none;
      margin: 40px 0 0;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  .carousel-cart {
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 5px 3px;
    border-radius: 8px;
    border: 1px solid #000;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      background: #bbdefb;
    }
  }

  .carousel-bought {
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #000;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
  }
}
