.admin-photo {
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  object-fit: contain;
}

.admin-image-container {
  position: relative;
  max-width: 150px;
  max-height: 150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  row-gap: 20px;
  column-gap: 20px;
}

.admin-image-cross {
  background: #1976d2;
  border: solid 1px #3b3b3b;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.admin-photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
}

.file-picker {
  min-width: 250px;
}

.admin-goods-item label {
  width: 500px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
}

label textarea {
  resize: vertical;
  width: 300px;
}

.admin-goods-item div {
  width: 1000px;
  align-items: flex-start;
  justify-content: space-between;
}

.admin-goods-item div label {
  width: 1000px;
  align-items: flex-start;
  justify-content: space-between;
}

.admin-goods-item div button {
  margin-bottom: 15px;
}