@import "~react-image-gallery/styles/scss/image-gallery.scss";

.product-carousel-container {
  max-width: 425px;
  max-height: 425px;
}

.product-carousel-image {
  max-width: 425px;
  max-height: 425px;
  object-fit: fill;
}