.content-container {
  box-sizing: border-box;
  margin-top: 120px;
  padding: 15px 35px 220px;
  background: #FCFCFC;
  min-height: 1000px;

  @media screen and (max-width: 1199px) {
    padding: 15px 15px 110px;
    margin-top: 80px;
  }
}

.triangle {
  position: fixed;
  right: -800px;
  top: -175px;
  z-index: -1;
  transform: rotate(2deg);

  @media screen and (max-width: 1199px) {
    display: none;
  }
}