.cart-items-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.cart-item-container {
  position: relative;
  padding: 20px 30px;
  background: #fff;
  text-decoration: none;
  border: 1px solid #c4c4c4;

  a {
    text-decoration: none;
  }
  .cart-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .cart-image {
      width: 189px;
      height: 189px;
      margin: 0 auto;
    }

    .cart-title {
      font-size: 18px;
      color: #000;
      text-decoration: none;
      margin: 20px 0 0;
      transition: all 0.5s ease-out;

      &:hover,
      &:focus,
      &:active {
        color: #3b3b3b;
      }
    }

    .cart-price {
      font-weight: bold;
      font-size: 18px;
      color: #000;
      text-decoration: none;
      margin: 40px 0 0;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }
}

.cart-form {
  max-width: 720px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .cart-form-input-container {
    position: relative;
    width: 100%;
    padding-bottom: 30px;
  }

  .cart-error {
    bottom: 0;
    left: 0;
    position: absolute;
    color: red;
    font-size: 12px;
  }

  .cart-form-input {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    -webkit-appearance: none;
    color: #444444;
    border: 1px solid #1976d2;
    background: #fff;
    transition: border 0.3s ease;

    @media screen and (max-width: 1199px) {
      width: 90%;
    }

    &::placeholder {
      color: #444444;
    }
    &:focus {
      outline: none;
      border-color: #03a9f4;
    }
  }
}

.cart-form-submit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 50px;
  margin-top: 30px;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }

  .cart-form-alert {
    font-size: 14px;
    color: #666;
  }

  .cart-form-button {
    width: 350px;
    height: 60px;
    border-radius: 4px;
    color: #212121;
    font-size: 18px;
    background: #fff;
    border: solid 2px #000;
    align-items: center;
    text-decoration: none;
    font-family: "Comfortaa", cursive;
    cursor: pointer;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      color: #3b3b3b;
    }
  }
}

.cart-remove {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 9px;
  border-radius: 8px;
  border: 1px solid #000;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &:active {
    background: #bbdefb;
  }
}

.cart-header {
  margin-top: 50px;
  margin-bottom: 30px;
}
