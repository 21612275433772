.shop-tile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 10px;
  margin-bottom: 100px;
  position: relative;

  a {
    text-decoration: none;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active .carousel-title {
      color: #3b3b3b;
    }
  }

  .shop-tile {
    background-image: url("../../shared/card-background.svg");
    background-size: cover;
    position: relative;
    padding: 20px 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    .shop-tile-item {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: row;
      align-items: flex-start;

      &:hover,
      &:focus,
      &:active .shop-tile-price {
        text-decoration: none;
      }
    }
  }


  .shop-tile-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 25px 0 0;
    max-width: 265px;
    min-width: 265px;

    .shop-tile-image {
      max-width: 265px;
      max-height: 265px;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .shop-tile-text {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    margin: 10px 0;
  }

  .shop-tile-title {
    font-size: 18px;
    color: #000;
    text-decoration: none;
    margin: 0;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      color: #3b3b3b;
    }
  }

  .shop-tile-price {
    font-weight: bold;
    font-size: 25px;
    color: #000;
    text-decoration: none;
    bottom: 10px;
    right: 40px;
    position: absolute;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }


  .shop-tile-cart-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 130px;
    border-radius: 8px;
    border: 1px solid #000;
    right: 40px;
    bottom: 80px;
    padding-left: 10px;
    color: #3b3b3b;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      background: #bbdefb;
    }

    .shop-tile-cart {
      position: absolute;
      right: 3px;
      bottom: 2px;
      padding: 5px 3px;
      max-width: 40px;
      max-height: 40px;
      box-sizing: border-box;
    }
  }

  .shop-tile-bought {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 110px;
    border-radius: 8px;
    border: 1px solid #000;
    right: 40px;
    bottom: 80px;
    padding-left: 10px;
    color: #3b3b3b;
    background-color: #fff;

    .shop-tile-cart {
      position: absolute;
      right: 3px;
      bottom: 5px;
      padding: 5px 3px;
      max-width: 40px;
      max-height: 40px;
      box-sizing: border-box;
    }
  }

  .shop-tile-description {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 15px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination {
  display: flex;
  margin-left: -40px;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #51C6F3;
  color: #fff;
}

.pagination > li > a {
  background: #2196F3;
  padding: 15px 25px;
  outline: none;
  cursor: pointer;
  font-size: 25px;

  &:hover {
    background-color: #6ec6ff;
  }
}

.pagination > li > a, .pagination > li > span {
  color: #fff;
  background-color: #2196F3;
}

.pagination-hidden-button {
  display: none;
}