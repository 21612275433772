.props-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.props-section {
  padding: 15px;
  color: #000;
  border: 1px solid #757575;
  word-break: break-word;

  &:nth-child(2n) {
    margin-left: -1px;
    margin-top: -1px;
  }

  &:nth-child(2n+1){
    margin-top: -1px;
  }


  &:nth-child(4n) {
    background-color: #fff;
  }

  &:nth-child(4n+1){
    background-color: #BBDEFB;
  }

  &:nth-child(4n+2){
    background-color: #BBDEFB;
  }

  &:nth-child(4n+3){
    background-color: #fff;
  }
}