.admin-categories-labels-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.admin-categories-labels-item {
  border: #000 solid 2px;
  padding: 20px;

  label {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
  }
}