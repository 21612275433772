.footer {
  width: 100%;
  background: #1976D2;
  padding-right: 30px;
  padding-left: 30px;

  @media screen and (max-width: 1199px) {
    width: unset;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 90px;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
}

.left-container-footer {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  align-items: center;
  margin-right: 100px;
  margin-top: 15px;

  @media screen and (max-width: 1199px) {
    column-gap: 25px;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
}

.footer-contact {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  margin-top: 30px;

  @media screen and (max-width: 1199px) {
    column-gap: 25px;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }

  &-container {
    display: flex;
    flex-direction: row;
    height: 21px;
    align-items: center;

    &-icon {
      padding-right: 10px;
    }

    &-link {
      color: #fff;
      text-decoration: none;
      transition: all 0.5s ease-out;

      &:hover, &:focus, &:active {
        color: #ddd;
      }
    }
  }
}

.category-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 30px;
  max-width: 90%;

  @media screen and (max-width: 1199px) {
    max-width: 100%;
  }
}

.category-item {
  height: 30px;
  & > a {
    color: #fff;
    text-decoration: none;
    transition: all 0.5s ease-out;

    &:hover, &:focus, &:active {
      color: #ccc;
    }
  }
}

.category-title {
  margin: 60px 0 25px 0;
  @media screen and (max-width: 1199px) {
    text-align: center;
  }
}

.footer-abstract {
  position: absolute;
  top: -300px;
  right: -200px;
  z-index: 10001;
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.footer-triangle {
  position: absolute;
  right: -1150px;
  top: -210px;
  z-index: 10001;
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.copyright {
  text-align: center;
  color: #BBDEFB;
  padding-bottom: 15px;
}