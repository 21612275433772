.shop-container {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
    row-gap: 20px;
  }
}

.shop-container-cards {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #000;
  padding-left: 15px;
  width: 100%;
  & h2 {
    margin-top: 0;
  }
  @media screen and (max-width: 1199px) {
    border-left: none;
  }
}

.shop-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1199px) {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 300px;
    column-gap: 20px;
  }
}

#scroll-top {
  @media screen and (max-width: 1199px) {
    display: inline-flex;
  }
}

.shop-container-switch {
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &:active {
    color: #1976D2;
  }
  @media screen and (min-width: 1199px) {
    display: inline-flex;
  }
}

.shop-container-switches {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
  @media screen and (max-width: 1199px) {
    column-gap: 20px;
  }
}

.shop-style-switch {
  @media screen and (max-width: 1199px) {
    display: none;
  }
}