.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  -webkit-appearance: none;
  color: #99a3ba;
  border: 1px solid #cdd9ed;
  background: #fff;
  transition: border 0.3s ease;
  &::placeholder {
    color: #cbd1dc;
  }
  &:focus {
    outline: none;
    border-color: #275efe;
  }
}

.form-group {
  position: relative;
  display: flex;
  width: 100%;
  & > .magnifier-span,
  .form-field {
    white-space: nowrap;
    display: block;
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
  .form-field {
    position: relative;
    z-index: 1;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
    &:not(:placeholder-shown) ~ .magnifier-span {
      color: #fff;
      background: #1976D2;
      border-color: #275efe;
    }
  }
  & > .magnifier-span {
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 25px;
    color: #99a3ba;
    background: #eef4ff;
    border: 1px solid #cdd9ed;
    transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: #1976D2;
      border-color: #275efe;
    }
  }
  &:focus-within {
    & > .magnifier-span {
      color: #fff;
      background: #1976D2;
      border-color: #275efe;
    }
  }
}

.magnifier-button {
  padding-top: 3px;
  height: 20px;
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  grid-template-areas: "select";
}

.select {
  width: 100%;
  min-width: 15ch;
  max-width: 30ch;
  border: 1px solid #1976d2;
  cursor: pointer;
  line-height: 1.1;
  background-color: #bbdefb;
  grid-area: select;
  align-items: center;
  position: relative;

  select {
    padding: 14px 12px 12px 14px;
  }
}

.select::after {
  content: "";
  border: solid black;
  border-width: 0 2px 2px 0;
  position: absolute;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 10px;
  top: 15px;
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

.select-block {
  display: flex;
  flex-direction: row;
}
