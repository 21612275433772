.categories-labels-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 50px;
  flex-wrap: wrap;

  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 100px;
    margin-bottom: 25px;
    gap: 10px;
    grid-auto-flow: dense;
    margin-top: 15px;
  }
}

.category-labels-item {
  color: #000;
  text-decoration: none;
  padding: 15px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: all 0.5s ease-out;

  @media screen and (max-width: 1199px) {
    padding: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    color: #3b3b3b;
  }

  .category-labels-count {
    font-size: 13px;
    color: #666;
    padding-left: 5px;
    padding-top: 3px;
    margin: 0;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .category-labels-name {
    font-size: 16px;
    color: #000;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      color: #1976D2;
    }
  }
}
