.shop-card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 30px;
  padding: 10px 0;
  margin-bottom: 100px;
  position: relative;

  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(1, 1fr);
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active .carousel-title {
      color: #3b3b3b;
    }
  }

  .shop-card {
    background-image: url("../../shared/card-background.svg");
    background-size: cover;
    position: relative;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.5s ease-out;

    &:hover {
      transform: scale(1.02)
    }

    .shop-card-item {
      width: 100%;
      &:hover,
      &:focus,
      &:active .shop-card-price {
        text-decoration: none;
      }
    }
  }

  .shop-card-image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 265px;

    .shop-card-image {
      max-width: 265px;
      max-height: 265px;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .shop-card-title {
    font-size: 18px;
    color: #000;
    text-decoration: none;
    margin: 0;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      color: #3b3b3b;
    }
  }

  .shop-card-price {
    font-weight: bold;
    font-size: 18px;
    color: #000;
    text-decoration: none;
    margin: 40px 0 0;
    max-width: 180px;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .shop-card-text {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    margin: 10px 0;
  }

  .shop-card-cart {
    position: absolute;
    right: 22px;
    bottom: 30px;
    padding: 5px 3px;
    border-radius: 8px;
    border: 1px solid #000;
    max-width: 40px;
    max-height: 40px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.5s ease-out;

    &:hover,
    &:focus,
    &:active {
      background: #bbdefb;
    }
  }

  .shop-card-description {
    min-height: 200px;
    background: #fff;
    padding: 15px 10px;
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
}

.pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pagination {
  display: flex;
  margin-left: -40px;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #51c6f3;
  color: #fff;
}

.pagination > li > a {
  background: #2196f3;
  padding: 15px 25px;
  outline: none;
  cursor: pointer;
  font-size: 25px;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: #6ec6ff;
  }
}

.pagination > li > a,
.pagination > li > span {
  color: #fff;
  background-color: #2196f3;
}

.pagination-hidden-button {
  display: none;
}

.shop-cards-container {
  display: flex;
  flex-direction: column;
}

.shop-card-bought {
  position: absolute;
  right: 22px;
  bottom: 30px;
  padding: 10px 9px;
  border-radius: 8px;
  border: 1px solid #000;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
}
