.product-card {
  background-image: url("../../shared/card-background.svg");
  background-size: cover;
  position: relative;
  padding: 20px 40px;
  min-width: 460px;
  min-height: 515px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  & p {
    margin: 0;
  }

  & h2 {
    margin: 0 0 10px;
  }

  & h4 {
    margin: 10px 0;
  }

  @media screen and (max-width: 1199px) {
    min-width: 320px;
    padding: 20px 5px;
  }
}

.product-card-desc {
  background-color: white;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card .product-card-vendor-code {
  color: #999999;
  margin-bottom: 10px;
}

.product-card-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  margin-bottom: 15px;
}

.product-card-section-category {
  max-width: 135px;
}

.product-card-section-desc {
  max-width: 150px;
}

.product-cart {
  display: flex;
  height: 60px;
  flex-direction: row;
  border-radius: 4px;
  justify-content: center;
  color: #212121;
  background: #fff;
  border: solid 2px #212121;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-out;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  &:hover,
  &:focus,
  &:active .shop-title {
    color: #3b3b3b;
  }

  &-icon {
    padding: 0 15px;
  }

  &-title {
    padding-right: 15px;
    color: #212121;
  }
}
