.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #1976d2;
  height: 120px;
  z-index: 100000;

  @media screen and (max-width: 1199px) {
    height: 80px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  padding-top: 7px;
  padding-bottom: 7px;
  align-items: center;

  @media screen and (max-width: 1199px) {
    justify-content: start;
    padding: 0;
  }
}

.header-icon {
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.left-container {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
  align-items: center;
  width: 100%;
  padding-top: 10px;

  @media screen and (max-width: 1199px) {
    padding-left: 15px;
    column-gap: 15px;
    padding-top: 0;
    justify-content: center;
    height: 80px;
  }
}

.shop {
  display: flex;
  height: 60px;
  flex-direction: row;
  border-radius: 4px;
  border: solid 2px #fff;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: #2986e2;
  }

  @media screen and (max-width: 1199px) {
    height: 45px;
  }

  &:hover,
  &:focus,
  &:active .shop-title {
    color: #ddd;
  }

  &-icon {
    padding: 0 15px;

    @media screen and (max-width: 1199px) {
      padding: 5px 10px;
    }
  }

  &-title {
    padding-right: 15px;
    color: #fff;

    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
}

.site-logo {
  width: 80px;
  height: 80px;
}

.cart {
  display: flex;
  height: 60px;
  flex-direction: row;
  border-radius: 4px;
  color: #212121;
  background: #fff;
  border: solid 2px #212121;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: #ddd;
  }

  @media screen and (max-width: 1199px) {
    height: 45px;
  }

  &:hover,
  &:focus,
  &:active .shop-title {
    color: #3b3b3b;
  }

  &-icon {
    padding: 0 15px;

    @media screen and (max-width: 1199px) {
      padding: 5px;
    }
  }

  &-title {
    padding-right: 15px;
    color: #212121;

    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  margin-top: 20px;

  @media screen and (max-width: 1199px) {
    display: none;
  }

  &-container {
    display: flex;
    flex-direction: row;
    height: 21px;
    align-items: center;

    &-icon {
      padding-right: 10px;

      @media screen and (max-width: 1199px) {
        padding-right: 5px;
      }
    }

    &-link {
      color: #fff;
      text-decoration: none;
      transition: all 0.5s ease-out;

      &:hover,
      &:focus,
      &:active {
        color: #ddd;
      }
    }
  }
}

.header-title {
  @media screen and (max-width: 1199px) {
    margin: 0;
    font-size: 28px;
  }
}

.header-title-container {
  color: #fff;
  text-decoration: none;
}

.abstract {
  position: absolute;
  top: -90px;
  left: -550px;

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.header-upper-container {
  position: absolute;
  left: 0;
  right: 0;
}
