.side-panel-block {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.side-panel-container {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 250px;
}

.side-panel-filter {
  display: flex;
  flex-direction: column;

  & h3{
    margin-top: 0;
    margin-bottom: 10px;
  }
  margin-bottom: 25px;
}

.side-panel-input {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  color: #000;
  & label {
    padding-left: 10px;
  }
}

.side-panel-slider {
  width: 215px !important;
}
.side-panel-range {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  & input {
    max-width: 50px;
    margin: 0 10px;
  }
}

.side-panel-button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
}

.side-panel-reset-button {
  background: none;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &:active {
    color: #1976D2;
  }
}

.side-panel-submit-button {
  width: 130px;
  border-radius: 4px;
  border: 1px solid #000;
  right: 40px;
  bottom: 30px;
  padding: 10px;
  background-color: #fff;
  color: #3b3b3b;
  cursor: pointer;
  margin-right: 30px;
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &:active {
    background: #bbdefb;
  }
}

.menu-icon-container {
  height: 50px;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  width: 50px;
  column-gap: 15px;

  @media screen and (min-width: 1199px) {
    display: none;
  }
}

.menu-icon-container-extended {
  width: 150px;
}