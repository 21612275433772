.main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  position: relative;
  z-index: 10000;

  @media screen and (max-width: 1199px) {
    display: block;
    width: 100%;
    margin: 0;
    max-width: none;
  }
}

.main-extended {
  margin-left: auto;
  margin-right: auto;
  max-width: 1800px;
  position: relative;
}
